import React, {useState} from "react"

import AdvancePlan from "./ad-plan2"
import Plans from "./plans"
import {Link} from "gatsby";
import xIconImg from "../../images/x-circle.svg";
import ReactGA from "react-ga";
import footerLeftVectorImg from "../../images/footer-left-vector.svg"
import footerRightVectorImg from "../../images/footer-right-vector.svg"

export default function Hero(props) {
    const [isPromoCode, setPromoCode ] = useState(false);
    const topBannerClick = () => {
        ReactGA.event({
            category: 'Pricing page_30 day_Banner',
            action: 'Pricing page_30 day_Banner'
        });
        gotoPricing();
    }

    const gotoPricing = () => {
        setPromoCode(true)
        try {
            document.querySelector('.plans').scrollIntoView({
                behavior: 'smooth',
                top: 0,
                left: 0,
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }

    const watchVideoModalClick = () => {
        ReactGA.event({
            category: 'PP_video_button',
            action: 'Pricing_Page_WatchHowitworks'
        });
        props.toggleVideoModal();
    }

    return (
    <div className="new-pricing__hero">
      <div className="new-pricing__hero-overlay"></div>
      <div className="footer-banner top-banner">
        <div className="text">
          Try LUCA Plus FREE for 30 days
        </div>
        <div className="flex">
          <img src={footerLeftVectorImg} />
          <div onClick={topBannerClick} className="btn font-medium">
            Get Started
          </div>
          <img src={footerRightVectorImg} />
        </div>
      </div>
      <div className="header">
        <div className="t1">
          Find a plan that's right for you
        </div>
        <div className="t2">
          Perfect Financial Solution to Get Paid Pay Bills on time
        </div>

        <div className="t3">
          Whether you are a sole trader or operating an SME, we have a plan for you.
          We've set up simple, flexible pricing options customised for your business needs
        </div>
          <div className="mt-8">
              <button className="play-btn focus:outline-none relative z-30" onClick={watchVideoModalClick}>Watch how it works
                  <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z"
                          fill="white"/>
                  </svg>

              </button>
          </div>
      </div>
        <Plans {...props} registrationDetails={{isPromoCode: true}} page={props.page} />
      <AdvancePlan page={props.page} />
    </div>
  )
}
