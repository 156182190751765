import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ReactGA from "react-ga";

export default function Request({toggleContactModal}) {
  const arrangeCallBackClick = () => {
    ReactGA.event({
      category: 'Pricing page_Arrange call back_Green button',
      action: 'Pricing page_Arrange call back_Green button clicked'
    });
    toggleContactModal();
  }
  return (
    <section className="new-pricing__request">
      <div className="container  flex  items-center h-full">
        <div className="  flex flex-col lg:flex-row justify-between items-center mx-auto w-4/5 ">
          <div className="left text-white text-center lg:text-left">
            <h2 className=" d-title mt-0 pt-0">
              Still undecided on which plan to choose?
            </h2>
            <p className="d-desc">
              Arrange a call back with one of our team members TODAY!
            </p>
          </div>
          <a name="cta_button" id="cta_button"></a>
          <div className="right mt-8  lg:mt-0">
            <a
                onClick={arrangeCallBackClick}
              className="bg-mind  text-white leading-tight focus:outline-none
            request-demo-btn2 cursor-pointer"
            >Arrange Call Back</a>
          </div>
        </div>
      </div>
    </section>
  )
}
