import React from "react"
import f1 from "../../images/pricing/features/f1.svg"
import f2 from "../../images/pricing/features/f2.svg"
import f3 from "../../images/pricing/features/f3.svg"
import f4 from "../../images/pricing/features/f4.svg"
import f5 from "../../images/pricing/features/f5.svg"
import f6 from "../../images/pricing/features/f6.svg"

export default function Featrues() {
  return (
    <div className="featrues sm:pb-10">
      <div className="f-title text-xl font-bold mt-16 pt-3 sm:mt-24 sm:text-2xl ">
        All Plans Cover The Basics
      </div>
      <div className="flex flex-col sm:flex-wrap mt-4 sm:flex-row pb-16 boxes mx-auto sm:text-center items-center justify-center sm:mt-16">
        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10">
          <img src={f1} alt="f1" />
          <h2 className="text-gray-17 mt-4">ATO & PEPPOL Certified</h2>
          <p className="text-gray-12 leading-snug text-14">
            LUCA Plus hosts a worldwide AS4 compatible access point.
          </p>
        </div>

        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10">
          <img src={f2} alt="f2" />
          <h2 className="text-gray-17 mt-4">Partner of XERO, MYOB and QBO</h2>
          <p className="text-gray-12 leading-snug text-14">
            LUCA Plus is a certified partner of industry leading accounting
            software providers XERO, MYOB and QBO.
          </p>
        </div>

        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10">
          <img src={f3} alt="f3" />
          <h2 className="text-gray-17 mt-4">Unlimited e-invoice</h2>
          <p className="text-gray-12 leading-snug text-14">
            Sending unlimited e-invoices to your customers and receiving
            unlimited e-bills from your suppliers.
          </p>
        </div>

        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10">
          <img src={f4} alt="f4" />
          <h2 className="text-gray-17 mt-4">Security</h2>
          <p className="text-gray-12 leading-snug text-14">
            Protecting your data is central to everything we do. LUCA Plus will
            never have permissions to modify anything. Our infrastructure is
            backed by ISO27001.
          </p>
        </div>

        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10 ">
          <img src={f5} alt="f5" />
          <h2 className="text-gray-17 mt-4">Verified by Blockchain</h2>
          <p className="text-gray-12 leading-snug text-14">
            All LUCA Plus transactions are validated by our blockchain. All
            transactions have a complete audit trail throughout the lifecycle.
          </p>
        </div>

        <div className="text-left mx-4 my-4 border-gray-16 border border-solid pl-12 pb-10 pr-12 pt-10">
          <img src={f6} alt="f6" className="w-8 h-8" />
          <h2 className="text-gray-17 mt-4">We are Here to Help</h2>
          <p className="text-gray-12 leading-snug text-14">
            Our team is ready to answer your questions or provide technical support via live chat.
            If the matter is urgent please call <br /> +61 (03) 9005 5308
          </p>
        </div>

      </div>
    </div>
  )
}
