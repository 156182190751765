import React, {useEffect, useLayoutEffect} from "react"
import {useCookies} from "react-cookie";
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Layout from "../components/layout"
import Partners from "../components/video/partners"
import Featrues from "../components/new-pricing/features"

import Hero from "../components/new-pricing/hero"

import Request from "../components/new-pricing/request"
import Modali, { useModali } from "../components/modal"
import {initializeAnalytics} from "../utils";
import Popup, {useModal} from "../components/popup";
import {GetCode2} from "../components/getcode";

export default function NewPricing(props) {
  const [contactModal, toggleContactModal] = useModali()
  const [videoModal, toggleVideoModal] = useModali()
  const [cookies, setCookie] = useCookies(['pricingPagePopup']);
  const [modalOptions, toggleModal] = useModal()

  useEffect(() => {
    // setTimeout(openPopup, 10000);
  }, [])

  const openPopup = () => {
    if(!cookies.pricingPagePopup){
      ReactGA.event({
        category: 'POP-UP',
        action: 'Open Pricing Page Pop-up'
      });
      toggleModal();
    }
  }
  return (
    <Layout headerFixed={true} simple={true} page="Pricing">
      <div className="h-full font-robo new-pricing">
        <SEO title="Electronic Invoicing Plans | LUCA+ Australia (XERO, MYOB, QuickBooks)" einvoice={true} description="Check out the LUCA+ electronic invoicing plans for Australian Businesses. Plans start from $10 per month."
             keywords="electronic invoicing plans"/>
        <Hero {...props} toggleVideoModal={toggleVideoModal} page="Pricing page" />
        <Partners />
        <Featrues />
        <Request toggleContactModal={toggleContactModal} />
      </div>
      <Modali.Modal {...contactModal} hubspot={true}>
        <div className="hero-hubspot">
          <iframe
            src="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
            title="Introduction to LUCA Plus"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/kJznAHK4wVM?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </Modali.Modal>
      <Popup.Modal {...modalOptions}>
        <GetCode2 close={toggleModal} />
      </Popup.Modal>
    </Layout>
  )
}
